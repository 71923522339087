// import react and gatsby libs
import React from 'react'
import { connect } from 'react-redux'
import {
  graphql,
  //navigate
} from 'gatsby'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'
import Background from '../assets/TableBG.jpg'

// import components
import Head from '../components/Head'
import Layout from '../components/Layout'
import Navigation from '../components/Navigation'
import BannerImage from '../components/BannerImage'
import Footer from '../components/Footer'

// styled Content element
const Content = styled.div`
  background-color: #fff;
  /*background-image: url(${Background});*/
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    width: 70vw;
    margin-left: 30vw;
    float: left;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */
    width: 80vw;
    margin-left: 20vw;
  `}
`

// styled Main element
const Main = styled.main`
  padding: 1rem;
  color: #222;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    padding: 2rem 2rem 4rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */
    padding: 4rem 4rem 4rem;
  `}

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
    line-height: 4rem;
    font-family: 'Glyph';
    font-weight: 300;
  }

  p {
    line-height: 1.75rem;
  }
`

// styled Article element
const Article = styled.article`

  iframe {
    border: 0;
  }

  .columns {

    text-align: center;

    display: grid;

    h2 {
      line-height: 1.2;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    a {
      text-decoration: none;
      color: #777;
      background: #efefef;
      display: inline-block;
      line-height: 2rem;
      font-family: sans-serif;
      font-size: 0.85rem;
      transform: translateZ(0);
      transition: all 0.1s ease;
      position: relative;
      padding: 0.25rem 1rem;
      border-radius: 3px;
      margin: 0.25rem 0;
      &:hover {
        color: #fff;
      }
      &:before {
        border-radius: 3px;
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #8cd2e2;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition-property: transform;
        transition: 150ms ease-out;
      }
      &:hover:before {
        transform: scaleX(1);
      }

    }

    ${media.greaterThan("small")`
      /* screen width is greater than 450px (small) */
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    `}

    ${media.greaterThan("medium")`
      /* screen width is greater than 768px (large) */
      padding: 0 4rem;
    `}

  }


`

// export pageTemplate component
const Downloads = function Template({
  data,
  authorized
}) {
  // get vars
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  // if (!authorized.authorized) {
  //   navigate("/")
  // }

  return(
    <Layout>
      <Head
        title={`${frontmatter.title} – How2charist`} />
      <Navigation />
      <Content>
        <Main>
          <BannerImage
            image={frontmatter.banner_image}
            alt={frontmatter.title} />
          <Article dangerouslySetInnerHTML={{ __html: html }} />
        </Main>
        <Footer />
      </Content>
    </Layout>
  )
}

// page query
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        banner_image
      }
    }
  }
`


// map state to props
const mapStateToProps = ({ authorized }) => {
  return { authorized }
}

// map dispatch to props
const mapDispatchToProps = dispatch => {
  return {
    // authorize: () => dispatch({ type: `AUTHORIZED` }),
    // validate: (formData) => dispatch(FormActions.submitValidateForm(formData))
  }
}

// ConnectedDownloads component
const ConnectedDownloads = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Downloads)

export default ConnectedDownloads
